import React from 'react';

export default function BunruiTable({ bunrui, selected, onChange }) {
  return (
    <div className="container w-100" >
      <div className="row row-cols-4">
      {Object.entries(bunrui || {}).map(([key, entry]) =>
        <button key={key}
        disabled={entry.count === 0}
        className={"col p-1 choice " 
          + (selected !== null && key === selected ? " choice-selected" : "")
          + (entry.count === 0 ? " choice-cannot" : "") }
        onClick={() => onChange(key)}>{key}</button>
      )}</div>
    </div>
  );
}