import React from 'react';

const Hinshi = [
  "名詞",
  "動詞",
  "形容詞",
  "形容動詞",
  "助動詞",
  "その他"
]

export default function SelectorPanel({ selected, onChange }) {
  return <div className="d-grid gap-2">
    {Hinshi.map(item =>
      <button type="button" key={item}
        className={"btn btn-block " + (selected.includes(item) ? "btn-primary" : "btn-secondary")}
        onClick={() => onChange(item)}
      >{item}</button>
    )}
  </div>;
}