import React from 'react';
import { RatingView } from 'react-simple-star-rating'

// フリガナの付加
function makeFurigana(word, yomi) {
  var hosoku = word.match(/[\(|\[\{].+[\)|\]\}]/g); // 見出しの (語彙素細分類)、[活用型]、{品詞} を抽出
  word = word.replace(hosoku, "");
  let origYomi = yomi;
  let m = word.match(/[\u3040-\u309f]+/g); // search all furigana
  if (!m) return [[[word, yomi]], hosoku];
  let okrgns = m.sort((a, b) => b.length - a.length); // okurigana
  for (let kana of okrgns) {
    yomi = yomi.replace(kana, "|");
  }
  if(yomi.charAt(yomi.length-1) !== "|") {
    // workaround: 由無し(よしなし) でバグ
    return [[[word, origYomi]], hosoku];
  }
  if(yomi === "|") { // only furigana
    return [[[word, ""]], hosoku];
  }

  let yomis = yomi.split('|');
  let result = [];
  while(true){
    if((m = word.match(/^[\u4e00-\u9faf]+/g))) { // start with kanji
      result.push([m[0], yomis.shift()]);
      word = word.slice(m[0].length);
    } else if((m = word.match(/^[\u3040-\u309f]+/g))){ // furigana
      result.push([m[0], ""]);
      word = word.slice(m[0].length);
    } else {
      break;
    }
  }
  return [result, hosoku];
}

const HinshiMap = { // バッジ
  "名詞": { short: "名", color: "#a1b817" },
  "動詞": { short: "動", color: "#17a2b8" },
  "形容詞": { short: "形", color: "#b82d17" },
  "形容動詞": { short: "形動", color: "#7d17b8" },
  "助動詞": { short: "助動", color: "#b87c17" },
  "その他": { short: "他", color: "#b8b8b8" }, 
};

function Word({word, yomi}) {
  let [frgn, hosoku] = makeFurigana(word, yomi);
  // let frgn = [[word, yomi]]; //問題ある場合
  return <div><ruby> { frgn.map((e, i) =>
    <React.Fragment key={i}>{e[0]}<rt>{e[1] === "" ? "　" : e[1]}</rt></React.Fragment>
  ) } </ruby><span className="ms-1 small">{hosoku}</span></div>;
}

export default function WordSelector({ words, selected, onChange }) {
  return (words || []).map((entry) =>
    <div className="d-inline-flex w-100" key={entry.hinshi + entry.word + entry.yomi}>
      <span className="badge align-self-center mr-2 badge-info"
        style={{backgroundColor: HinshiMap[entry.viewhinshi].color}}>{HinshiMap[entry.viewhinshi].short}</span>
      <div className="w-100">
        <button
        className={"choice " + (selected !== null 
          && entry.hinshi === selected.hinshi 
          && entry.word === selected.word
          && entry.yomi === selected.yomi ? " ms-2 choice-selected" : "") }
        onClick={() => onChange(entry)}><Word word={entry.word} yomi={entry.yomi} /></button></div>
      <div className="w-25 word-ref align-self-center" >
        <RatingView
          ratingValue={entry.rating[0]} 
          fillColor={"#000000"}
          emptyColor={"#CCCCCC"}
          size={16}
        />
        <a href={"https://japanknowledge.com/lib/search/basic/?q1=" + encodeURIComponent(entry.word)}
           target="_blank" rel="noopener noreferrer" className="jk">J<span className="k">K</span></a>  
        <a href={"https://kobun.weblio.jp/content/" + encodeURIComponent(entry.word)}
           target="_blank" rel="noopener noreferrer" className="weblio"><span className="w">w</span>eblio</a>
      </div>
    </div>
  );
}