import React from 'react';

export default function HorizontalResultTable({ result, header }){
  return <>
        <div className="pt-2 pb-2 text-end">{header}</div>
        <table className="table table-bordered table-sm p-0 panel m-0">
          <tbody>{ result.map((row, i) => <tr key={i}>
            <td className="text-end">{row.before}</td>
            <td className="table-warning text-center text-nowrap">{row.word}</td>
            <td className="text-start">{row.after}</td>
            <td className="text-nowrap">{row.cform}</td>
            <td className="text-nowrap">{row.title} {row.subtitle}</td>
            <td className="tjk"><a href={row.link} target="_blank" rel="noopener noreferrer" className="jk">J<span className="k">K</span></a></td>
          </tr>)
          }</tbody>
        </table>
        </>;
}
