import React from 'react';

const HiraganaMatrix = [
  [ // Left
    ["あ", "い", "う", "え", "お"],
    ["か", "き", "く", "け", "こ"],
    ["さ", "し", "す", "せ", "そ"],
    ["た", "ち", "つ", "て", "と"],
    ["な", "に", "ぬ", "ね", "の"]
  ],
  [ // Right
    ["は", "ひ", "ふ", "へ", "ほ"],
    ["ま", "み", "む", "め", "も"],
    ["や", null, "ゆ", null, "よ"],
    ["ら", "り", "る", "れ", "ろ"],
    ["わ", "ゐ", null, "ゑ", "を"],
    ["ん", null, null, null, null]
  ]
];

export default function HiraganaTable({ selected, onChange }) {
  return (
    <div className="d-flex flex-wrap">
      {
        HiraganaMatrix.map((table,i) =>
          <div className="flex-fill" key={i}>
            <table className="char-table"><tbody>
              {table.map((tr,i) => <tr key={i}>
                {tr.map((char,i) =>
                  (char === null) ? <td key={i}></td> :
                    <td key={i}>
                      <button
                        type="button"
                        className={"btn " + (selected === char ? "btn-primary" : "btn-outline-secondary")}
                        onClick={() => onChange(char)}
                      >{char}</button>
                    </td>
                )}
              </tr>)}
            </tbody></table>
          </div>
        )
      }
    </div>
  );
}