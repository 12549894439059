import React, {useMemo, Fragment} from 'react';
import { RatingView } from 'react-simple-star-rating'
import { BsFillDiamondFill } from "react-icons/bs";
import { IconContext } from 'react-icons'

export default function TitleSelector({ titles, frequency, adjustmentFrequency, rating, selected, onChange }) {
  const categories = useMemo(() => // 時代
    [...new Set(titles.map(t => t.category))]
  ,[titles]);

  return categories.map(cat =>
    <Fragment key={cat}>
    {/* <div style={cat !== "鎌倉" ? {} : {"break-inside": "avoid"}}> */}
    <div>
    <h3 className="h4 mt-1 fw-bold">{cat}</h3>
    { 
        titles.filter(t => t.category === cat).map(title =>
        <div className="d-inline-flex  w-100" key={title.index}>
        <div className="w-75">
        {(() => {
            return (
              <span><button
              disabled={frequency[title.index] === 0}
              className={"choice fs-5 " + (title.title === selected ? " choice-selected" : "")
                + (frequency[title.index] === 0 ? " choice-cannot" : "") }
              onClick={() => onChange(title.title)}>{title.title}</button>
               </span>
            );
        })()}
        </div>
        <div className="w-25 word-ref align-self-center text-nowrap">
          <RatingView
            ratingValue={rating[title.index + 1]} // rating[0] は語の rating のため +1
            fillColor={"#000000"}
            emptyColor={"#CCCCCC"}
            size={16}
          >
            <IconContext.Provider value={{ size: '12px' }}>
              <BsFillDiamondFill fill='currentColor'/>
            </IconContext.Provider>
          </RatingView>
          <div className="w-100">
            <div className="w-50 d-inline-block text-end">
            {frequency[title.index]}　
            </div>
            <div className="w-50 d-inline-block text-end">
            {adjustmentFrequency[title.index].toFixed(1)}
            </div>
          </div>
        </div>
        </div>
      )
    }
    </div>
    </Fragment>
  );
  /**/
}