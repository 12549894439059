import React, { useState, useEffect, useContext } from 'react';
import ReactGA from "react-ga4";
import VerticalResultTable from './VerticalResultTable';
import HorizontalResultTable from './HorizontalResultTable';
import { AppContext } from '../App.js';

function encodeParam(paramList){
  let ss = [];
  for(let [key, value] of paramList){
    ss.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
  }
  return ss.join('&');
}

/*
function toKatakana(str){
  return str.replace(/[\u3041-\u3096]/g, (c) => String.fromCharCode(c.charCodeAt()+0x60));
}
*/

function normalizeHinshi(h){
  let conditions = h === "形容動詞" ? String.raw`名詞-普通名詞-形状詞可能%" OR 品詞 LIKE "形状詞` : h;
  return `( 品詞 LIKE "${conditions}%" )`;
}
function getQueryEqValue(k, v){
  let ret = '( ';
  for(let value of v) {
    ret += `${k}="${value}" OR `
  }
  ret = ret.slice(0, -3) + ")";
  return ret;
}
function createKeyParam(param, hinshi){
  let eq = Object.entries(param.eq).map(([key, value]) => getQueryEqValue(key, value));
  let like = Object.entries(param.like).map(([key, value]) => `${key} LIKE "${value}%"`);
  return `(${ eq.join(' AND ') } AND ${ like.join(' AND ') } AND ${normalizeHinshi(hinshi)})` 
}

export default function CorpusTable({ word, title }) {
  const [result, setResult] = useState(null); // result of API
  const [vertical, setVertical] = useContext(AppContext);

  function handleResult(json){ // 結果の受け取り
    // console.log(json);
    let res = [];
    for(let row of json.result.rows) {
      res.push({
        before: row.lcontext ? row.lcontext.map(e => e.orth_token).join('') : "",
        word: row.keyword ? row.keyword.map(e => e.orth_token).join('') : "",
        after: row.rcontext ? row.rcontext.map(e => e.orth_token).join('') : "",
        cform: row.cform ? row.cform.replace(/-一般/,"") : "",
        title: row.title ? row.title : "",
        subtitle: row.subtitle ? row.subtitle : "",
        link: (row.external_links && row.external_links.some) ? row.external_links[0].url : ""
      })
    }
    setResult(res);
  }  

  // This procedure is called if word or title are changed
  useEffect(() => {
    setResult(null);

    // Google Analytics 4
    if (ReactGA.isInitialized) {
      ReactGA.event('search')
    }

    // Use legacy XHR due to Canellation
    let xhr = new XMLHttpRequest();
    xhr.open("POST", process.env.REACT_APP_API_ENDPOINT, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    xhr.withCredentials = true;

    xhr.onreadystatechange = function() { // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            // Request finished. Do processing here.
            handleResult(JSON.parse(this.responseText));
        }
    }
    
    let coreExpr = title === "今昔物語集" ? ' AND core="true"' : "" ;
    xhr.send(encodeParam([
      ["condDescription", "検索条件式で検索"],
      ["retrievalMethod", "短単位検索"],
      ["queryExpr", `キー: ${createKeyParam(word.search, word.hinshi)} IN 作品名="${title}"${coreExpr}`]
    ]));
    
    return () => {
      // request is cancelled if word or title are changed
      console.log("cancelled");
      xhr.abort();
    };
  }, [word, title]);

  if (result === null) { // Loading
    return <div className="d-flex justify-content-center">
      <div className="spinner-border text-light m-5" style={{width: "5rem", height: "5rem"}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>;
  } else { // Show results
    let header = <button className="btn btn-info" onClick={() => setVertical(!vertical)}>{vertical ? "横書き" : "縦書き"}に切り替える</button>;
    return <>
      { (vertical) ? 
          <VerticalResultTable result={result} header={header} /> 
        : <HorizontalResultTable result={result} header={header} /> }
    </>;
  }
}
