import React, { useState, useRef, useEffect } from 'react';
import Swipe, { SwipeItem } from 'swipejs/react';


function eachSlice(arr, size){
  let ret = []
  for (let i = 0, l = arr.length; i < l; i += size){
    ret.push(arr.slice(i, i + size))
  }
  return ret
}

export default function VerticalResultTable({ result, header }){
  function getPageCount(){ // calculate page size from width.
    let size = Math.min(1200,window.innerWidth) / (50 + 48000/window.innerHeight) | 0;
    return { size, index: Math.floor(Math.max(0,result.length-1)/size) };
  }

  // SwipeJs Component
  let swipeEl = useRef(null);
  
  let [ page, setPage] = useState(getPageCount()); // page number and number of entries in a page
  let pages = eachSlice(result, page.size).reverse();

  // Dynamic Change of page index
  function changePageSize(){
    let newPage = getPageCount();
    if(newPage !== page.size) {
      setPage(newPage);
      swipeEl.current.slide(newPage.index);
    }
  }
  useEffect(() => { // add/remove EventHandler for resizing
    window.addEventListener("resize", changePageSize, false);
    return () => window.removeEventListener("resize", changePageSize, false);
  }, []);

  return <>
    <div className="text-white text-center p-2" style={{fontSize: "large", position:"relative"}}>
      <div><button type="button" className="btn btn-outline-light mx-2"
              disabled={page.index === 0}
              onClick={() => swipeEl.current.prev()}>次</button>
       {pages.length-page.index} / {pages.length} 
      <button type="button" className="btn btn-outline-light mx-2" 
              disabled={page.index >= pages.length-1}
              onClick={() => swipeEl.current.next()}>前</button></div>
      <div style={{position:"absolute", top:0, right:0}} className="pt-2">{header}</div>
    </div>
    <Swipe auto={0} startSlide={page.index} callback={(c) => setPage({ ...page, index: c })} ref={swipeEl}>{
      pages.map((page, i) =>
        <SwipeItem key={i}><table className="table verticaltable">
          <tbody>{ page.map((row, i) => <tr key={i}>
            <td className="text-end">{row.before}</td>
            <td className="table-warning text-center text-nowrap">{row.word}</td>
            <td className="text-start">{row.after}</td>
            <td className="text-nowrap">{row.cform}</td>
            <td className="text-nowrap">{row.title} {row.subtitle}</td>
            <td className="tjk"><a href={row.link} target="_blank" rel="noopener noreferrer" className="jk">J<span className="k">K</span></a></td>
          </tr>)
          }</tbody>
        </table></SwipeItem>
      )
    }</Swipe>
  </>;
}
