import React, { useState, useRef, useEffect, useMemo, createContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './App.css';

import HinshiSelector from './components/HinshiSelector';
import HiraganaTable from './components/HiraganaTable';
import PrefixSelector from './components/PrefixSelector';
import KoumokuTable from './components/KoumokuTable';
import BunruiTable from './components/BunruiTable';
import WordSelector from './components/WordSelector';
import TitleSelector from './components/TitleSelector';
import CorpusTable from './components/CorpusTable';

export const AppContext = createContext([0, () => {}]);
const Dictionary = require('./dict.json');

function App() {
  // States https://reactjs.org/docs/hooks-state.html
  const [char, setChar] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [hinshi, setHinshi] = useState(["名詞"]);
  const [koumoku, setKoumoku] = useState(null);
  const [bunrui, setBunrui] = useState(null);
  const [word, setWord] = useState(null);
  const [title, setTitle] = useState(null);
  const wordPanel = useRef(null);
  const titlePanel = useRef(null);
  const resultTable = useRef(null);
  const [vertical, setVertical] = useState(false); // Vertical or not
  const [tabKey, setTabKey] = useState('yomi');

  // 単語
  const matchedWordsByChar = useMemo(() => 
    (Dictionary.dict || [])
      .filter((word) => word.prefix.startsWith(prefix)) // filter by prefix
      .filter((word) => hinshi.includes(word.viewhinshi)) // filter by hinshi
      .filter((word) => word.freq.some((v) => v !== 0)) // 検索結果 0 件のみのデータは表示しない
      .sort((a,b) => a.yomi < b.yomi ? -1 : (a.yomi > b.yomi ? 1 : 0)) // sort by yomi
  , [prefix, hinshi]);
  const matchedWordsBykoumoku = useMemo(() => 
    (Dictionary.dict || [])
      .filter((word) => word.bunruiIds.some(v =>
         Dictionary.wlsp.koumoku[koumoku]?.bunrui[bunrui]?.ids.includes(v))) // filter by bunruiId
      .filter((word) => word.freq.some((v) => v !== 0)) // 検索結果 0 件のみのデータは表示しない
      .sort((a,b) => a.yomi < b.yomi ? -1 : (a.yomi > b.yomi ? 1 : 0)) // sort by yomi
    , [koumoku,bunrui]);

  // SmoothScroll
  function scrollInto(el){
    el && el.current && el.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  useEffect(() => scrollInto(wordPanel), [char, hinshi]);
  useEffect(() => scrollInto(titlePanel), [word]);
  useEffect(() => scrollInto(resultTable), [title]);

  return (
    <div className="container-fluid mb-3" style={{maxWidth: 1200}}>
      <div class="container">
        <div class="row">
          <h1 className="title h2 mt-2 col-auto me-auto">ことねり <small>『日本語歴史コーパス』簡易検索ツール</small></h1>
          <div className="col-auto">
            <a href="https://sites.google.com/ogiso.net/cotoneri/" target="_blank" className="btn-help btn btn-info">ヘルプページ</a>
          </div>
        </div>
      </div>

      <Tabs
      id="controlled-tab-example"
      activeKey={tabKey}
      onSelect={(k) => {setTitle(null); setWord(null); setTabKey(k); }}
      className="mb-3 search-tabs"
      >
      <Tab eventKey="yomi" title="読みで検索">
        <div className="panel">
            <div className="row">
              <div className="col-sm-9">
                <p className="lead">調べたい言葉の最初の三字と品詞を選んでください。</p>
                <PrefixSelector prefix={prefix} onChange={(p) => {
                  setTitle(null); setWord(null); setChar(null); setPrefix(p);
                  }} />
                <HiraganaTable selected={char} onChange={(c) => {
                  setTitle(null); setWord(null); setChar(c);
                  if (prefix.length < 3) {
                    setPrefix( prefix + c);
                  }
                  }} />
              </div>
              <div className="col-sm-3">
                <HinshiSelector selected={hinshi}
                  onChange={(h) => { 
                    setTitle(null); setWord(null);
                    if(hinshi.includes(h)){
                      setHinshi(hinshi.filter(e => e !== h)); // toggle
                    } else {
                      setHinshi([...hinshi, h]); // toggle
                    }
                  }} />
              </div>
            </div>
          </div>
          <div ref={wordPanel}>{ (prefix !== "" && hinshi !== null) &&
            <div className="panel selector word-selector" >
                  <WordSelector words={matchedWordsByChar} 
                            selected={word} onChange={(w) => { setTitle(null); setWord(w) }} />
            </div>
          }
        </div>
      </Tab>
      <Tab eventKey="imi" title="意味で検索" >
        <div className="panel">
          <div className="row">
            <div className="col-sm-12">
              <p className="lead">調べたい言葉の意味を選んでください。</p>
              <KoumokuTable koumoku={Dictionary.wlsp.koumoku} selected={koumoku} 
                onChange={(c) => { setTitle(null); setWord(null); setBunrui(null); setKoumoku(c) }} />
              <hr />
              <BunruiTable bunrui={Dictionary.wlsp.koumoku[koumoku]?.bunrui} selected={bunrui} 
                onChange={(c) => { setTitle(null); setWord(null); setBunrui(c) }} />
            </div>
          </div>
        </div>
        <div ref={wordPanel}>{ (bunrui !== null) &&
          <div className="panel selector word-selector" >
                <WordSelector words={matchedWordsBykoumoku} 
                          selected={word} onChange={(w) => { setTitle(null); setWord(w) }} />
          </div>
        }</div>
      </Tab>
    </Tabs>
      <div ref={titlePanel}>{ word !== null &&
        <div className="panel selector">
          <TitleSelector
           titles={Dictionary.titles}
           frequency={word.freq}
           adjustmentFrequency={word.adjustmentFreq}
           rating={word.rating}
           selected={title} onChange={w => setTitle(w)} /> 
        </div>
      }</div>
      <AppContext.Provider value={[vertical, setVertical]}>
      <div ref={resultTable}>{
        word !== null && title !== null &&
          <CorpusTable word={word} title={title} />
      }</div>
      </AppContext.Provider>
    </div>
  );
}

export default App;
