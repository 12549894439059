import React from 'react';

export default function KoumokuTable({ koumoku, selected, onChange }) {
    return (
      <div className="container">
        <div className="row ">
          {Object.entries(koumoku).map(([key, entry],i) =>
            <div className="mt-1 pe-1" style={{width: "140px"}} key={i}>
              <button
                type="button"
                disabled={Object.entries(entry.bunrui).some(([k, v]) => v.count === 0)}
                className={"w-100 btn " 
                  + (selected === key ? "btn-primary" : 
                  (Object.entries(entry.bunrui).some(([k, v]) => v.count === 0)  ? "btn-koumoku-disabled" : "btn-outline-secondary"))}
                onClick={() => onChange(key)}
              >{key}</button>
            </div>
          )}
        </div>
      </div>
    );
}